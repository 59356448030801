.navbarcontainer {
    display: flex;
    flex-flow: column wrap;
    align-content: center;
    gap: 50px;
    justify-content: flex-start;
    padding-top: 30px;
    background-color:rgb(189, 247, 255);
    flex: 1 2 2; 
    width:5%;
    min-width: 30px;
    height:100%;
}
