.App {
  text-align: center;
}

.main_container {
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  max-height: 100vh;
}

.item {

}

/* .toolbar {
  background-color: rgb(106, 238, 255);
} */