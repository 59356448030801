@keyframes example {
    from {background-color: white;}
    to {background-color: grey;}
  }
  
  /* The element to apply the animation to */
  .readerdiv {
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    animation-name: example;
    animation-duration: 200ms;
  }

  .footer {
    background-color: white;
    animation-name: example;
    animation-duration: 200ms;
  }
  .nextpagebutton {
    max-width: 100px;
    min-width: 100px;
    max-height: 50px;
    border-radius: 25px;
     min-height: 50px;
     background-color: #ECECEC; 
     justify-content:center;
     align-items: center; 
     display:flex
    }