.navbardiv {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start; 
    align-content: left;
    align-items: flex-start;
    padding-top: 1%;
    padding-left: 1%;
    background-color: #ECECEC;
    height:30vh;
    width:90vw ;
    z-index:1000;
    text-align: left;
    gap:80px;
    -moz-box-shadow:    inset 0 0 20px #ECECEC;
    -webkit-box-shadow: inset 0 0 20px #ECECEC;
    box-shadow:         inset 0 0 20px #ECECEC;
    overflow: scroll;
    font-family: JoanRegular;
    position: relative;
}

.sectionlist {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start; 
    align-content: left;
    align-items: flex-start;
    /* gap:10px; */
    font-size: .9rem;
    font-family: JoanRegular;
    text-decoration: none;
    padding:4px;
    color: black !important; 
}