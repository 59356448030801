::-webkit-scrollbar {
    width: 0px;
  }

  .pagecontainer {
    display: flex;
    flex-flow: row nowrap;
    gap: 1px;
    height: 100%;
  }

.lrcontainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    gap: 30px;
    width: 100%;
    height:100%;
    background-color:rgb(226, 245, 253);
    padding: 20px;
  }
  
.articlecontainer {
    flex-flow: column wrap;
    flex: 1 2 2;
    width: 45%;
    border-radius: 5px;
    background-color:white;
    align-content: space-around;
    overflow:scroll;
}

.configcontainer {
    display: flex;
    flex-flow: column nowrap;
    align-content: left;
    justify-content: left;
    flex: 1 2 2;
    overflow: scroll;
    width: 50%;
    border-radius: 5px;
    background-color:white;
    align-items: flex-start;
    padding: 5px;
}

.configelement {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width:75%;
}

.sidebarcontainer {
    display: flex;
    flex-flow: column wrap;
    align-content: flex-start;
    background-color:rgb(189, 247, 255);
    flex: 1 2 2; 
    width: 5%;
    height:100%;
}

.metadatadiv {
  background-color: rgb(189, 247, 255);
  border-radius: 5px;
  padding: 10px 21px;
  cursor: pointer;
}
