.nextvizbutton {
    max-width: 300px;
    max-height: 50px;
    min-width: 300px;
    border-radius: 25px;
     min-height: 50px;
     background-color: #ECECEC; 
     justify-content:center;
     align-items: center; 
     display:flex;
     cursor: pointer;
    }

    .moardata {
        width: 80%;
        min-width: 100px;
        border-radius: 10px;
        min-height: 50px;
        max-height: 50px;
        background-color: #ECECEC; 
        justify-content:center;
        align-items: center; 
        display:flex;
        cursor: pointer;
        }