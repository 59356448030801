.pagecontainerlist {
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    gap: 1px;
    height: 100%;
  }

.lrcontainerlist {
    display: flex;
    flex-flow: column nowrap;
    height:100%;
    width:90%;
    justify-content: flex-start;
    align-content: center;
    padding: 10px;
    gap:10px;
    overflow:scroll;
  }

  .metadatadiv {
    background-color: rgb(189, 247, 255);
    border-radius: 5px;
    padding: 10px 21px;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }