body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: "JoanRegular";
    src: local("JoanRegular"),
    url("./fonts/Joan-Regular.ttf") format("truetype");
    font-weight: bold;
}

a {
    color: black;
}

@font-face {
    font-family: "JoanRegular";
    src: local("JoanRegular"),
    url("./fonts/Joan-Regular.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Stencil";
    src: local("Stencil"),
    url("./fonts/X.Template-font-stencil.ttf") format("truetype");
    font-weight: bold;
}
    