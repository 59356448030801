input {

    /* styling */
    background-color: white;
    border: thin solid rgb(106, 238, 255);
    border-radius: 2px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;
  
    /* reset */
  
    margin: 0;      
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: auto !important;

  }

textarea {

    /* styling */
    background-color: white;
    border: thin solid rgb(106, 238, 255);
    border-radius: 2px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;
  
    /* reset */
  
    margin: 0;      
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: auto !important;

  }

select {

    /* styling */
    background-color: white;
    border: thin solid rgb(106, 238, 255);
    border-radius: 2px;
    display: inline-block;
    font: inherit;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;
  
    /* reset */
  
    margin: 0;      
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: auto !important;

  }
  
  
  
  select.classic {
    background-image:
      linear-gradient(45deg, transparent 50%, rgb(189, 247, 255) 50%),
      linear-gradient(135deg, rgb(189, 247, 255) 50%, transparent 50%),
      linear-gradient(to right, rgb(189, 247, 255), rgb(189, 247, 255));
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      100% 0;
    background-size:
      5px 5px,
      5px 5px,
      2.5em 2.5em;
    background-repeat: no-repeat;
  }
  
  select.classic:focus {
    background-image:
      linear-gradient(45deg, white 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, white 50%),
      linear-gradient(to right, gray, gray);
    background-position:
      calc(100% - 15px) 1em,
      calc(100% - 20px) 1em,
      100% 0;
    background-size:
      5px 5px,
      5px 5px,
      2.5em 2.5em;
    background-repeat: no-repeat;
    border-color: grey;
    outline: 0;
  }
  
  button {
    align-items: center;
    appearance: button;
    background-color: rgb(189, 247, 255);
    border-radius: 8px;
    border-style: none;
    box-shadow: rgba(255, 255, 255, 0.26) 0 1px 2px inset;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    flex-shrink: 0;
    font-family: "RM Neue",sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    padding: 10px 21px;
    text-align: center;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button:active {
    background-color: #006AE8;
  }
  
  .button:hover {
    background-color: #1C84FF;
  }